@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


html {
    font-family: 'Poppins', sans-serif;
}

/* custom scrollbar */
::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

@layer components {
    .hover-lift {
        @apply transition hover:scale-105 hover:shadow-xl;
    }

    .btn-hover {
        @apply transition hover:text-white hover:bg-indigo-700;
    }

    .html-display ul {
        @apply list-disc ml-5 px-2 pt-2
    }

    .markup p {
        margin-bottom: 1rem;
    }

    .markup ul {
        list-style-type: disc;
        margin-left: 1rem;
        padding: .25rem .5rem 0 .5rem;
    }

    .markup ul ul {
        list-style-type: circle;
    }
}

@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
    }
  }

.DreamRocket-blue-background {
    background-color: #0094FF;
}  
.DreamRocket-blue-text {
    color: #0094FF;
}  

.bottom-leaf-box-shadow {
    box-shadow: 0px -4px 4px 0px #00000040;
}